<template>
  <div class="container mt-5 register-box">
    <h1 class="font-weight-bold">Register</h1>
    <a-form layout="vertical">
      <validation-provider
        rules="required"
        name="username"
        v-slot="{ errors, valid }"
      >
        <a-form-item
          label="username"
          has-feedback
          :validate-status="errors[0] ? 'error' : valid ? '' : 'warning'"
        >
          <a-input v-model="username" name="username" placeholder="username" />
          <validate-error
            v-if="validation.username"
            :errors="validation.username"
          />
        </a-form-item>
      </validation-provider>
      <validation-provider
        rules="required"
        name="email"
        v-slot="{ errors, valid }"
      >
        <a-form-item
          label="email"
          has-feedback
          :validate-status="errors[0] ? 'error' : valid ? '' : 'warning'"
        >
          <a-input v-model="email" name="email" placeholder="email" />
          <validate-error v-if="validation.email" :errors="validation.email" />
        </a-form-item>
      </validation-provider>

      <validation-provider
        rules="required"
        name="password"
        v-slot="{ errors, valid }"
      >
        <a-form-item
          label="password"
          has-feedback
          :validate-status="errors[0] ? 'error' : valid ? '' : 'warning'"
        >
          <a-input v-model="password" type="password" placeholder="password" />
          <validate-error
            v-if="validation.password"
            :errors="validation.password"
          />
        </a-form-item>
      </validation-provider>
      <a-form-item label="organization">
        <a-select
          v-model="organizationId"
          allow-clear
          placeholder="organization"
          style="width: 100%"
        >
          <a-select-option
            v-for="organization in organizations"
            :key="organization.id"
            :value="organization.id"
          >
            {{ organization.name }}
          </a-select-option>
        </a-select>
        <validate-error v-if="validation.role" :errors="validation.role" />
      </a-form-item>
      <a-form-item label="role">
        <a-select
          v-model="role"
          allow-clear
          placeholder="role"
          style="width: 100%"
        >
          <a-select-option key="superadmin" value="superadmin"
            >superadmin</a-select-option
          >
          <a-select-option key="admin" value="admin">admin</a-select-option>
        </a-select>
        <validate-error v-if="validation.role" :errors="validation.role" />
      </a-form-item>
      <a-form-item>
        <a-button type="primary" :loading="isRegistering" @click="submit">
          Submit
        </a-button>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import useRegister from "@/composables/use-register";
import ValidateError from "@/components/common/ValidateError";
export default {
  components: { ValidateError },
  setup() {
    const {
      username,
      email,
      password,
      role,
      organizationId,
      validation,
      organizations,
      register,
      isRegistering
    } = useRegister();
    return {
      username,
      email,
      password,
      role,
      organizationId,
      validation,
      organizations,
      register,
      isRegistering
    };
  },
  methods: {
    async submit() {
      const res = await this.register();
      if (res === true) {
        this.$message.success("Register Succesfull");
        return;
      }
      this.$message.error("Register Fail!");
    }
  }
};
</script>

<style lang="scss" scoped>
.register-box {
  max-width: 540px;
}
</style>
