import validationError from "@/helpers/display-error";
import AdminService, { OrganizationMe } from "@/services/admin.service";
import { onMounted, reactive, toRefs } from "@vue/composition-api";
interface UseRegisterState {
  username?: string;
  email?: string;
  role: string;
  password?: string;
  organizationId?: string;
  validation: any;
  isRegistering: boolean;
  organizations: OrganizationMe[];
}
const useRegister = () => {
  const state = reactive<UseRegisterState>({
    username: "",
    email: "",
    role: "admin",
    password: "",
    organizationId: undefined,
    validation: {},
    isRegistering: false,
    organizations: []
  });

  const register = async () => {
    state.isRegistering = true;
    state.validation = {};
    const { username, email, role, password, organizationId } = state;
    const { validation, status } = await AdminService.register({
      username,
      email,
      role,
      organizationId,
      password
    });
    state.isRegistering = false;
    if (status === 201) {
      return true;
    } else {
      const validate = await validationError(validation);
      state.validation = validate.validation;
      return validate;
    }
  };
  onMounted(async () => {
    state.organizations = await AdminService.getOrganizationMe();
  });
  return { ...toRefs(state), register };
};

export default useRegister;
